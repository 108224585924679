import { createAction, props } from '@ngrx/store';
import { ClubItem } from '../../../models/club-item.interface';

export const loadClubs = createAction('[Club List] Load all clubs');

export const loadClubsSuccess = createAction(
  '[Club List] Load all clubs success',
  props<{ data: ClubItem[] }>(),
);

export const editClub = createAction(
  '[Club List] Edit club',
  props<{ id: number; status: string }>(),
);

export const createClub = createAction('[Club List] Create club');

export const deleteClub = createAction(
  '[Club List] Delete club',
  props<{ id: number; status: string }>(),
);
